import './App.css';
import { LandingPage } from './landing/LandingPage';

function App() {
  return (
    <LandingPage/>
  );
}

export default App;
