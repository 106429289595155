import React from 'react'
import Gradients from '../constants/Gradients'

export default function Features2({
  features, image, head, title, description
}) {
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-8 lg:pt-4 bg-white">
                <h2 className={`text-base font-semibold leading-7 ${Gradients.textGradient}`}>{head}</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-darkblue sm:text-4xl">{title}</p>
                <img
                className={`mt-10 md:-ml-4 lg:-ml-0 ${image.bg}`}
                src={image.src} alt={image.alt} width={image.width} height={image.height}/>
            </div>
            <div className="lg:pr-8 lg:pt-4 bg-white">
                <p className="mt-6 text-lg leading-8 text-gray-600">{description}</p>

                <dl className="mt-10 max-w-xl space-y-5 text-base leading-7 text-gray-600 lg:max-w-none">
                    {features.map((feature) => (
                    <div key={feature.name} className="relative pl-9">
                        <dt className="inline font-semibold text-gray-900">
                        <feature.icon className={`absolute top-1 left-1 h-5 w-5 text-teal-400 aria-hidden="true}`} />
                        {feature.name}
                        </dt>{' '}
                        <dd className="inline">{feature.description}</dd>
                    </div>
                    ))}
                </dl>
                </div>
            </div>

        </div>
      </div>
    )
}
