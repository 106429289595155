import React from 'react';
import { HandRaisedIcon } from '@heroicons/react/24/outline';
import { AcademicCapIcon } from '@heroicons/react/24/outline';

export default function OurMission() {
  return (
    <div id="our-mission" className="relative isolate overflow-hidden bg-darkblue py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 lg:max-w-none lg:grid-cols-2">
          <div className="max-w-xl lg:max-w-lg">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                Our Mission
            </h2>
            <p className="mt-4 text-xl leading-8 text-gray-300">
            Our mission at MLOpsCore is to empower businesses of all sizes to harness the full potential
            of Artificial Intelligence. We strive to streamline the entire machine
            learning process with our innovative MLOps tools, enabling developers to
            build, train, deploy, and maintain machine learning models with ease.
            </p>
          </div>
          <dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">
            <div className="flex flex-col items-start">
              <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                <AcademicCapIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <dt className="mt-4 font-semibold text-white">State of the Art</dt>
              <dd className="mt-2 leading-7 text-gray-400">
              Leveraging the latest technologies and best practices in the industry and helping ours customers to stay ahead of the competition
              </dd>
            </div>
            <div className="flex flex-col items-start">
              <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                <HandRaisedIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <dt className="mt-4 font-semibold text-white">Accessible</dt>
              <dd className="mt-2 leading-7 text-gray-400">
                Dedicated to making artificial inteligence more accessible and understandable to everyone than ever
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <svg
        className="absolute top-0 left-1/2 -z-10 h-[42.375rem] -translate-x-1/2 blur-3xl xl:-top-6"
        viewBox="0 0 1155 678"
        fill="none"
      >
        <path
          fill="url(#09dbde42-e95c-4b47-a4d6-0c523c2fca9a)"
          fillOpacity=".3"
          d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
        />
        <defs>
          <linearGradient
            id="09dbde42-e95c-4b47-a4d6-0c523c2fca9a"
            x1="1155.49"
            x2="-78.208"
            y1=".177"
            y2="474.645"
            gradientUnits="userSpaceOnUse"
          >
          {/* "9089FC" "#FF80B5" */}
            <stop stopColor="#45A8C3" />
            <stop offset={1} stopColor="#6D2C8F" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}