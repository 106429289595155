import React from 'react'
import Gradients from '../constants/Gradients'

export default function MainLogo({backgroundColor}) {
  return (
    <svg
    className={`h-5 -rotate-45 w-auto fill-current text-transparent ${Gradients.buttonGradient}`}
    xmlns="http://www.w3.org/2000/svg"
    version='1.1'
	  viewBox="0 0 522 522">

      <path d="M0,0v522h522V0H0z M228.4,424.1H97.9V97.9h130.5V424.1z M424.1,424.1H293.6V293.6h130.5V424.1z
          M424.1,228.4H293.6V97.9h130.5V228.4z"/>

      <rect x="97.9" y="97.9" fill={backgroundColor} width="130.5" height="326.2"/>
      <rect x="293.6" y="97.9" fill={backgroundColor} width="130.5" height="130.5"/>
      <rect x="293.6" y="293.6" fill={backgroundColor} width="130.5" height="130.5"/>
      <rect x="97.9" y="97.9" fill={backgroundColor} width="130.5" height="326.2"/>
      <rect x="293.6" y="293.6" fill={backgroundColor} width="130.5" height="130.5"/>
      <rect x="293.6" y="97.9" fill={backgroundColor} width="130.5" height="130.5"/>

    </svg>
  )
}
