import React from 'react'
import Hero from './Hero'

import Features2 from './Features2';

import OurMission from './OurMission';

import Subscribe from './Subscribe';
import TrackingImage from '../resources/captura.png';
import StdMLImage from '../resources/stdml-image-01.svg';
import AIEngine from '../resources/ai-engine.svg'
import Gradients from '../constants/Gradients';

import { CheckIcon } from '@heroicons/react/20/solid';

export const LandingPage = () => {

  const features_models = [
    {
      name: 'Automate your machine learning workflow.',
      description:
        'Use containers to automate your machine learning workflow, reducing manual errors and enabling rapid iteration and deployment.',
      icon: CheckIcon,
    },
    {
      name: 'Monitor and manage your models with ease.',
      description: 'With a single command, your team can monitor and manage the entire model lifecycle, from data preparation and model development to deployment and ongoing monitoring.',
      icon: CheckIcon,
    },
    {
      name: 'Streamline your machine learning process.',
      description: 'By using containers, you can simplify your machine learning process and enable your team to focus on driving business outcomes, rather than spending time on manual tasks.',
      icon: CheckIcon,
    },
  ]

  const features_business = [
    {
      name: 'Reduce risk and ensure compliance.',
      description:
        'Tracking your model development can help you meet regulatory and ethical requirements, reducing the risk of non-compliance and improving your reputation with stakeholders.',
      icon: CheckIcon,
    },
    {
      name: 'Foster collaboration and innovation.',
      description: ' By tracking your model development in a centralized location, you can enable collaboration and knowledge sharing among team members, leading to more innovative and effective development.',
      icon: CheckIcon,
    },
    {
      name: 'Improve model accuracy and efficiency. ',
      description: 'By tracking your model development, you can identify areas for improvement and optimize your models for better performance, resulting in increased accuracy and efficiency.',
      icon: CheckIcon,
    }
  ]

  const features_compose = [
    {
      name: 'Streamline your ML workflows',
      description: 'Our platform allows you to easily build and manage your machine learning pipelines, enabling you to rapidly experiment with new models and iterate on your existing ones. Our platform integrates with your existing tooling, so you can continue to use the tools you know and love.',
      icon: CheckIcon,
    },
    {
      name: 'Deploy models anywhere',
      description: 'Our platform enables you to deploy your models to any cloud provider, on-premises infrastructure, or edge device. We provide you with a unified deployment interface that abstracts away the complexity of different deployment targets and allows you to focus on delivering high-quality models.',
      icon: CheckIcon,
    },
    {
      name: 'Optimize for performance',
      description: 'Our platform provides comprehensive monitoring and logging capabilities, allowing you to track the performance of your models and identify areas for optimization. ',
      icon: CheckIcon,
    }
  ]


  return (
    <>
      <Hero/>
      <OurMission/>
      <div id='features' className='pt-10'>
        <p className='text-4xl text-center font-bold tracking-tight text-darkblue sm:text-4xl'>Core Features</p>
      <div className='px-10 divide-y divide-gray-500/10'>
      <Features2
        head={"Artificial Intelligence Lifecycle"}
        title={"AI models are containers"}
        description={"Simplify your machine learning workflow by automatically training, evaluating, and deploying your models through containers. With a single command, your team can monitor and manage the entire model lifecycle, streamlining your machine learning process."}
        features={features_models}
        image={{
          src: AIEngine,
          alt: "Product screenshot", width: "100%", height: "auto",
          bg: Gradients.buttonGradient
        }}
      />

      <Features2
        head={"Operate, Train & Deploy"}
        title={"Automate and Scale your ML development"}
        description={"Tracking your model development can provide a competitive advantage for your business. Here are three ways it can benefit you:"}
        features={features_compose}
        image={{
          src: StdMLImage,
          alt: "StdmlCompose Diagram", width: "100%", height: "auto",
          bg: Gradients.buttonGradient
        }}
        />
      <Features2
        head={"Track your business"}
        title={"Gain a competitive edge with tracking"}
        description={"Provide a comprehensive solution for data scientists and engineers to automate and scale their ML workflows."}
        features={features_business}
        image={{
          src: TrackingImage,
          alt: "Product screenshot", width: "100%", height: "auto",
          bg: "rounded-xl shadow-xl ring-1 ring-gray-400/10"
        }}
      />

      </div>
      </div>
      {/* <CTA/> */}
      <Subscribe/>
      <div className='py-4 flex items-center justify-center bg-darkblue'>
        <p className='text-white leading-7'>Copyright© 2023 MLOpsCore. All rights reserved.</p>
      </div>
    </>
  );
}
