const tailwindGradientForText = "font-extrabold animate-text bg-clip-text text-transparent";

let tailwindGradientColors = "bg-gradient-to-br from-teal-400 to-pink-500 via-purple-500";
// tailwindGradientColors = "bg-gradient-to-br from-pink-400 via-purple-500 to-teal-400";


const Gradients = {
    textGradient: `bg-gradient-to-r ${tailwindGradientForText} ${tailwindGradientColors}`,
    buttonGradient: `bg-gradient-to-br ${tailwindGradientColors} animate-gradient-x`
}

export default Gradients;