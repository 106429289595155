import { React, useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon, FireIcon, ArrowUpRightIcon } from '@heroicons/react/24/outline'

import Modal from './Modal';
import Gradients from '../constants/Gradients';
import MainLogo from './MainLogo';

const navigation = [
  { name: 'Our mission', href: '#our-mission' },
  { name: 'Features', href: '#features' },
]

export default function Hero() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [contactOpen, setContactModalOpen] = useState(false);

  return (
    <div className="bg-gray">
      <Modal isOpen={contactOpen} closeModal={()=>setContactModalOpen(false)} />
      <header className="absolute inset-x-0 top-0 z-50">

        <div className="bg-darkblue py-3 flex justify-center items-center">
          <FireIcon className='h-4 text-white'/>
          <p className='leading-7 ml-2 text-white font-light'>
            We are in  South Summit 2023!
          </p>
          <a href="https://www.southsummit.co/es/" className='text-white font-bold ml-2'>Get all the info here</a>
          <ArrowUpRightIcon className='h-3 text-white ml-1'/>
        </div>

        <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <a href="/" className="-m-1.5 p-1.5 flex items-center justify-between">
              <span className="sr-only">MLOpsCore</span>
              <MainLogo backgroundColor={"white"}/>
              <p className='pl-3 text-lg'>
                <span className="font-bold">MLOps</span>
                <span className="font-bold">Core</span>
              </p>
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12 flex items-center">
            {navigation.map((item) => (
              <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900">
                {item.name}
              </a>
            ))}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <button
            onClick={()=>setContactModalOpen(true)}
            className={`
              bg-cyan-600
              hover:bg-cyan-500
              ${Gradients.buttonGradient}
              rounded-md
              px-3.5 py-2.5 text-sm font-semibold
              text-white
              shadow-sm
              focus-visible:outline focus-visible:outline-2
              focus-visible:outline-offset-2
              focus-visible:outline-indigo-600`}
          >
          Let's talk! <span aria-hidden="true">→</span>
          </button>
          </div>
        </nav>
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed flex flex-col justify-between inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className='flex-none'>
              <div className="flex items-center justify-between">
                <a href="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">MLOpsCore</span>
                  <MainLogo backgroundColor={"white"}/>
                </a>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="mt-6 flow-root flex-col justify-between">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div className="space-y-2 py-6">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <div className="py-6">
                    <button
                      onClick={()=>setContactModalOpen(true)}
                      className={`
                        bg-cyan-600
                        hover:bg-cyan-500
                        ${Gradients.buttonGradient}
                        rounded-md
                        px-3.5 py-2.5 text-sm font-semibold
                        text-white
                        shadow-sm
                        focus-visible:outline focus-visible:outline-2
                        focus-visible:outline-offset-2
                        focus-visible:outline-indigo-600`}
                    >
                    Let's talk! <span aria-hidden="true">→</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-none">
              <p className="text-2xl font-bold tracking-tight text-darkblue sm:text-2xl">Train</p>
              <p className="text-2xl font-bold tracking-tight text-darkblue sm:text-2xl">Evaluate</p>
              <p className="text-2xl font-bold tracking-tight text-darkblue sm:text-2xl">Deploy</p>
              <p className="text-2xl font-bold tracking-tight text-darkblue sm:text-2xl">Repeat!</p>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80">
          <svg
            className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
            viewBox="0 0 1155 678"
          >
            <path
              fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
              fillOpacity=".3"
              d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
            />
            <defs>
              <linearGradient
                id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
                x1="1155.49"
                x2="-78.208"
                y1=".177"
                y2="474.645"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#9089FC" />
                <stop offset={1} stopColor="#FF80B5" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="mx-auto max-w-4xl py-32 sm:py-48 lg:py-56">
          <div className="hidden sm:mb-8 sm:flex sm:justify-center">
            <div className="relative rounded-full py-1 px-3 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
              Announcing stdml-compose Library soon.{' '}
              {/* <a href="#" className="font-semibold text-indigo-600">
                <span className="absolute inset-0" aria-hidden="true" />
                Read more <span aria-hidden="true">&rarr;</span>
              </a> */}
            </div>
          </div>
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-darkblue sm:text-6xl">
              Bring your AI Models the lifecycle they deserve
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
             Deliver value over the long term by getting a comprehensive lifecycle approach that covers every stage of the model's development, deployment and maintenance.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <button
                      onClick={()=>setContactModalOpen(true)}
                className={`
                  bg-cyan-600
                  hover:bg-cyan-500
                  ${Gradients.buttonGradient}
                  rounded-md
                  px-3.5 py-2.5 text-sm font-semibold
                  text-white
                  shadow-sm
                  focus-visible:outline focus-visible:outline-2
                  focus-visible:outline-offset-2
                  focus-visible:outline-indigo-600`}
              >
                Let's talk! <span aria-hidden="true">→</span>
              </button>

            </div>
          </div>
        </div>
        <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
          <svg
            className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
            viewBox="0 0 1155 678"
          >
            <path
              fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
              fillOpacity=".3"
              d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
            />
            <defs>
              <linearGradient
                id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
                x1="1155.49"
                x2="-78.208"
                y1=".177"
                y2="474.645"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#9089FC" />
                <stop offset={1} stopColor="#FF80B5" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  )
}
